// components/layout/Layout.jsx

import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import './layoutClient.css';

const LayoutClient = () => {
    const { isAuthenticated, logout } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogout = () => {
        logout(); // 로그아웃 처리
        navigate('/'); // 메인 페이지로 이동
    };

    return (
        <>
            <div className='header'>
                <div className='front-section'>
                    <div className='logo-wrap'><div className='inner'><img src={`${process.env.PUBLIC_URL}/img/logo.png` } /><p>i-WOORI<br/>아이우리세무법인</p></div></div>
                </div>
            </div>
        </>
    )
}

export default LayoutClient;
