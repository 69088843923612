import React, { useState, useEffect } from 'react';
import { signup, checkEmailDuplication, sendThirdPartyData, sendAuthResult, fetchBoards, fetchPlayers } from '../../api';
import { useLocation, Link, useNavigate, useParams, UNSAFE_NavigationContext } from 'react-router-dom';
import LayoutClient from '../../components/layout/LayoutClient';
import Modal from '../../components/modal';

const Verify = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalConfirmAction, setModalConfirmAction] = useState(() => () => {});
  const location = useLocation();
  const { userName, identity, phoneNo } = location.state || {};
  const { shopname, webname } = useParams();
  const [formData, setFormData] = useState({
    userName: userName || '',
    identity: identity || '',
    phoneNo: phoneNo || '',
    userIdentity: '',
    companyIdentityNo: '',
    loginTypeLevel: '1',
    telecom: '',
  });
  const [players, setPlayers] = useState([]);
  const [boards, setBoards] = useState([]);
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const [currentBoard, setCurrentBoard] = useState(null);
  const [resultData, setResultData] = useState(null);
  const [authResponse, setAuthResponse] = useState(null);  // 간편인증 요청 응답 상태
  const [signedData, setSignedData] = useState(''); // 간편인증 후 사용자가 입력한 서명 데이터
  const [isAuthRequested, setIsAuthRequested] = useState(false); // 인증 요청 상태
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [loginTypeLevel, setLoginTypeLevel] = useState('1'); // 인증 방식 상태
  const [telecom, setTelecom] = useState(''); // 통신사 상태

  const handleLoginTypeChange = (e) => {
    setLoginTypeLevel(e.target.value);
    setFormData(prev => ({ ...prev, loginTypeLevel: e.target.value }));
    if (e.target.value !== '5') {
      setTelecom('');
      setFormData(prev => ({ ...prev, telecom: '' }));
    }
  };

  const handleTelecomChange = (e) => {
    setTelecom(e.target.value);
    setFormData(prev => ({ ...prev, telecom: e.target.value }));
  };

  const openModal = (message, onConfirm) => {
    setModalMessage(message);
    setModalConfirmAction(() => onConfirm);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleConfirm = () => {
    modalConfirmAction();
    closeModal();
  };

  useEffect(() => {
    loadPlayers();
    loadBoards();
  }, []);

  useEffect(() => {
    if (players.length > 0 && boards.length > 0) {
      filterData();
    }
  }, [players, boards, shopname, webname]);
  
  const loadPlayers = () => {
    fetchPlayers().then((response) => {
      setPlayers(response.data);
    }).catch((error) => {
      console.error("Error loading players:", error);
    });
  };

  const loadBoards = () => {
    fetchBoards().then(response => {
      setBoards(response.data);
    }).catch(error => {
      console.error('Error loading teams:', error);
    });
  };

  const filterData = () => {
    const board = boards.find(b => b.name_en === shopname);
    const player = players.find(p => p.name_en === webname);
  
    setCurrentBoard(board || null);
    setCurrentPlayer(player || null);
  
    if (board) {
      setFormData(prevFormData => ({
        ...prevFormData,
        companyIdentityNo: board.business_no
      }));
    }
  };



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // 간편인증 요청 핸들러
  const handleAuthRequest = async (e) => {
    e.preventDefault();

    // 생년월일 8자리 체크
    if (formData.identity.length !== 8) {
      alert('생년월일을 8자리로 입력해주세요');
      return;  // 함수 종료
    }

    setIsAuthRequested(true);
    setErrorMessage('');

    alert('간편인증 요청 중입니다.');

    try {
      const authData = {
        organization: '0004',
        loginType: loginTypeLevel,
        loginTypeLevel: loginTypeLevel,
        userName: formData.userName,
        identity: formData.identity,
        phoneNo: formData.phoneNo,
        companyIdentityNo: formData.companyIdentityNo,
        userIdentity: formData.userIdentity,
        telecom: loginTypeLevel === '5' ? telecom : '', // 통신사 선택 시 추가
      };

      const response = await sendThirdPartyData(authData);
      setAuthResponse(response);

      if (response.result.code === 'CF-03002') {
        alert('간편인증이 진행 중입니다. 앱에서 인증을 완료해주세요.');
      }
    } catch (error) {
      console.error('Error sending auth request:', error);
      alert('간편인증 요청 중 오류가 발생했습니다. \n 정보를 다시 확인해주세요.');
      setIsAuthRequested(false);
    }
  };

  // 간편인증 완료 후 재요청 핸들러
  const handleAuthResult = async () => {
    setErrorMessage('');
    setLoading(true);

    if (!authResponse) {
      alert('먼저 간편인증 요청을 진행해주세요.');
      return;
    }

    try {
      const authData = {
        organization: '0004',
        loginType: loginTypeLevel,
        loginTypeLevel: loginTypeLevel,
        userName: formData.userName,
        identity: formData.identity,
        phoneNo: formData.phoneNo,
        companyIdentityNo: formData.companyIdentityNo,
        userIdentity: formData.userIdentity,
        simpleAuth: '1', // 간편인증 방식
        is2Way: true,
        twoWayInfo: {
          jobIndex: authResponse.data.jobIndex,
          threadIndex: authResponse.data.threadIndex,
          jti: authResponse.data.jti,
          twoWayTimestamp: authResponse.data.twoWayTimestamp,
        },
        telecom: loginTypeLevel === '5' ? telecom : '', // 통신사 선택 시 추가
      };

      // 간편인증 완료 후 재요청
      const resultResponse = await sendAuthResult(authData);
      const { result, data } = resultResponse;

      if (result.code === 'CF-00000') { 
        if (data.resSuccessYN === '0') {
          navigate('/apply/overlap');
        } else {
          setResultData(data);
          if ( formData.userIdentity.length > 6 ) {
            var inputName = data.resTaxAgentList[0].resCompanyNm1
          } else {
            var inputName = formData.userName
          }
          const signData = {
            name: inputName,
            tax_name: currentBoard.id,
            phone_no: formData.phoneNo,
            confirm_date: data.resTaxAgentList[0].resCommissionDate,
            cms_check: currentPlayer.id,
            identity: formData.userIdentity
          };
          signup(signData).then(response => {
            const { userId } = response.data;
      
            // confirm 페이지로 userId를 함께 넘깁니다.
            navigate(`/apply/${shopname}/${webname}/confirm`, {
              state: { userId } // userId를 상태값으로 함께 넘김
            });
          });
        }
      } else {
        setErrorMessage(result.message);
        alert('인증 중 오류가 발생했습니다. \n 정보를 다시 확인해주세요.');
        setIsAuthRequested(false);
      }

    } catch (error) {
      console.error('Error sending auth result:', error);
      alert('인증 중 오류가 발생했습니다. \n 정보를 다시 확인해주세요.');
      setIsAuthRequested(false);
    } finally {
      setLoading(false); // 로딩 종료
    }
  };

  return (
    <>
      <LayoutClient />
      <div className='client-contents cms'>
        <div className='inner'>
          <div className='image-wrap'><img src={`${process.env.PUBLIC_URL}/img/img-write.svg`} /></div>
          <div className='contents-wrap cms'>
            <form onSubmit={handleAuthRequest} className='login-form'>
              <p className='sub-title'>수임동의 진행을 위해<br/> 간편인증을 진행해주세요.</p>

              {/* 인증 방식 선택 */}
              <div className='input-wrap'>
                <p className='label'>인증 방식 선택</p>
                <div className='radio-wrap'>
                  <label>
                    <input
                      type="radio"
                      name="loginTypeLevel"
                      value="5"
                      checked={loginTypeLevel === '5'}
                      onChange={handleLoginTypeChange}
                    />
                    PASS(통신사)
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="loginTypeLevel"
                      value="3"
                      checked={loginTypeLevel === '3'}
                      onChange={handleLoginTypeChange}
                    />
                    삼성패스
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="loginTypeLevel"
                      value="6"
                      checked={loginTypeLevel === '6'}
                      onChange={handleLoginTypeChange}
                    />
                    네이버
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="loginTypeLevel"
                      value="8"
                      checked={loginTypeLevel === '8'}
                      onChange={handleLoginTypeChange}
                    />
                    Toss
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="loginTypeLevel"
                      value="1"
                      checked={loginTypeLevel === '1'}
                      onChange={handleLoginTypeChange}
                    />
                    카카오톡
                  </label>
                </div>
              </div>

              {/* 통신사 선택 (loginTypeLevel이 5일 때만 표시) */}
              {loginTypeLevel === '5' && (
                <div className='input-wrap'>
                  <p className='label'>통신사 선택</p>
                  <select name="telecom" value={telecom} onChange={handleTelecomChange} required>
                    <option value="">통신사를 선택해주세요</option>
                    <option value="0">SKT(SKT 알뜰폰)</option>
                    <option value="1">KT(KT 알뜰폰)</option>
                    <option value="2">LG U+(LG U+ 알뜰폰)</option>
                  </select>
                </div>
              )}

              <div className='input-wrap'>
                <p className='label'>이름</p>
                <input type="text" name="userName" placeholder="이름" value={formData.userName} onChange={handleChange} required />
              </div>
              <div className='input-wrap'>
                <p className='label'>휴대폰 번호</p>
                <input type="number" name="phoneNo" inputmode="numeric"
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(0, e.target.maxLength);
                }}
                maxLength={11} placeholder="'-'하이픈 없이 숫자로 입력해주세요" value={formData.phoneNo} onChange={handleChange} required />
              </div>
              {/*<button type="button" onClick={handlePhoneCheck}>중복체크</button>*/}
              <div className='input-wrap'>
                <p className='label'>주민등록번호</p>
                <input type="number" name="identity" inputmode="numeric" placeholder="생년월일 8자리 ex)19900618" value={formData.identity} onChange={handleChange} required />
              </div>
              <div className='input-wrap'>
                <p className='label'>사업자등록번호<br/>{'('}비사업자의 경우 생년월일 6자{')'}</p>
                <input type="number" name="userIdentity" inputmode="numeric" placeholder="사업자등록번호 or 생년월일 6자" value={formData.userIdentity} onChange={handleChange} required />
              </div>
              {/* 인증 요청 버튼이 인증 완료 버튼으로 변경됨 */}
              {isAuthRequested ? (
                <>
                  <p className="guide-txt">인증요청하신 앱에서 인증 후 완료버튼을 눌러주세요.</p>
                  <button type="button" onClick={handleAuthResult}>
                    인증완료
                  </button>
                </>
              ) : (
                <button type="submit" >인증요청</button>
              )}
            </form>
            {/* 간편인증 완료 후 서명 데이터 입력 */}
          </div>
        </div>
        {/* API 결과를 화면에 출력 */}
      </div>
      <Modal
        isOpen={modalIsOpen}
        message={modalMessage}
        onClose={closeModal}
        onConfirm={handleConfirm}
      />
      {loading && (
        <div className='loading-spinner'>
          <div className='spinner'></div>
          <p>인증을 확인중입니다.<br/>잠시만 기다려주세요</p>
        </div>
      )}
    </>
  );
};

export default Verify;