import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { sendDismissalAuth, sendDismissalResult } from '../../api';
import LayoutClient from '../../components/layout/LayoutClient';
import Modal from '../../components/modal';

const Dismissal = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalConfirmAction, setModalConfirmAction] = useState(() => () => {});
  const location = useLocation();
  const { shopname, webname } = useParams();
  const { resultData, userName, identity, phoneNo } = location.state || {};
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [authResponse, setAuthResponse] = useState(null);  // 간편인증 요청 응답 상태
  const [isAuthRequested, setIsAuthRequested] = useState(false); // 인증 요청 상태
  const [selectedLoginTypeLevel, setSelectedLoginTypeLevel] = useState('1'); // 선택된 인증 방식
  const [selectedTelecom, setSelectedTelecom] = useState(''); // 선택된 통신사
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    organization: "0004",
    loginType: "5",
    loginTypeLevel: "1",
    userName: userName,
    identity: identity,
    phoneNo: phoneNo,
    telecom: ''
  });

  const telecomOptions = [
    { value: '0', label: 'SKT(SKT 알뜰폰)' },
    { value: '1', label: 'KT(KT 알뜰폰)' },
    { value: '2', label: 'LG U+(LG U+ 알뜰폰)' }
  ];

  // 통신사 선택 핸들러
  const handleTelecomChange = (e) => {
    setSelectedTelecom(e.target.value);
    setFormData((prevData) => ({
      ...prevData,
      telecom: e.target.value
    }));
  };

  // 인증 방식 선택 핸들러
  const handleLoginTypeLevelChange = (e) => {
    setSelectedLoginTypeLevel(e.target.value);
    setFormData((prevData) => ({
      ...prevData,
      loginTypeLevel: e.target.value,
      telecom: '' // 인증 방식이 변경되면 통신사 선택 초기화
    }));
  };

  const openModal = (message, onConfirm) => {
    setModalMessage(message);
    setModalConfirmAction(() => onConfirm);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleConfirm = () => {
    modalConfirmAction();
    closeModal();
  };

  // 간편인증 요청 핸들러
  const handleDismissalRequest = async (e) => {
    e.preventDefault();
    setIsAuthRequested(true);
    setErrorMessage('');

    alert('간편인증 요청 중입니다.');

    try {
      const response = await sendDismissalAuth(formData);
      setAuthResponse(response);

      if (response.result.code === 'CF-03002') {
        alert('간편인증이 진행 중입니다. 인증을 완료해주세요.');
      }
    } catch (error) {
      console.error('Error sending auth request:', error);
      alert('간편인증 요청 중 오류가 발생했습니다. \n 정보를 다시 확인해주세요.');
      setIsAuthRequested(false);
    }
  };

  // 간편인증 완료 후 재요청 핸들러
  const handleDismissalResult = async () => {
    setErrorMessage('');
    if (!authResponse) {
      alert('먼저 간편인증 요청을 진행해주세요.');
      return;
    }

    setLoading(true);

    try {
      const authData = {
        organization: "0004",
        loginType: "5",
        loginTypeLevel: "1",
        userName: userName,
        identity: identity,
        phoneNo: phoneNo,
        simpleAuth: '1', // 간편인증 방식
        is2Way: true,
        twoWayInfo: {
          jobIndex: authResponse.data.jobIndex,
          threadIndex: authResponse.data.threadIndex,
          jti: authResponse.data.jti,
          twoWayTimestamp: authResponse.data.twoWayTimestamp,
        }
      };

      // 간편인증 완료 후 재요청
      const resultResponse = await sendDismissalResult(authData);
      const { result, data } = resultResponse;
      if (result.code === 'CF-00000') {
        openModal(
          '기존 세무대리인 해임이 완료되었습니다.',
          () => {
            navigate(`/dismissal/result`);
          }
        );
      } else {
        setErrorMessage(result.message);
        setIsAuthRequested(false);
      }
    } catch (error) {
      console.error('Error sending auth result:', error);
      alert('인증 중 오류가 발생했습니다. \n 정보를 다시 확인해주세요.');
      setIsAuthRequested(false);
    } finally {
        setLoading(false); // 로딩 종료
    }
  };

  return (
    <>
      <LayoutClient />
      <div className='client-contents'>
        <div className='inner'>
          <div className='image-wrap'><img src={`${process.env.PUBLIC_URL}/img/img-write.svg`} /></div>
          <div className='contents-wrap'>
            <div className='login-form'>
              <p className='sub-title'>기존에 등록된 세무대리인입니다.<br/> 아래 해임신청 버튼을 클릭하여 인증 진행 후 재신청 바랍니다. </p>
                {resultData ? (
                  <>
                    <div className='result-details'>
                        <div className='input-wrap single'>
                            <div className='inputs'>
                            <p className='label'>상호명</p> {resultData.resCompanyNm}
                            </div>
                            <div className='inputs'>
                            <p className='label'>세무대리인 사업자번호</p> {resultData.resCompanyIdentityNo}
                            </div>
                            <div className='inputs'>
                            <p className='label'>수임일자</p> {resultData.resCommissionDate}
                            </div>
                            <div className='inputs'>
                            <p className='label'>수임동의 여부</p> {resultData.resInfoProvideAgreeYN}
                            </div>
                        </div>
                    </div>
                    <div className='input-wrap'>
                      <p className='label'>인증 방식 선택</p>
                      <div onChange={handleLoginTypeLevelChange} className='radio-wrap'>
                      <label>
                        <input type="radio" value="1" checked={selectedLoginTypeLevel === '1'} /> 카카오톡
                      </label>
                      <label>
                        <input type="radio" value="3" checked={selectedLoginTypeLevel === '3'} /> 삼성패스
                      </label>
                      <label>
                        <input type="radio" value="5" checked={selectedLoginTypeLevel === '5'} /> 통신사(PASS)
                      </label>
                      <label>
                        <input type="radio" value="6" checked={selectedLoginTypeLevel === '6'} /> 네이버
                      </label>
                      <label>
                        <input type="radio" value="8" checked={selectedLoginTypeLevel === '8'} /> Toss
                      </label>
                    </div>
                    </div>
                    {selectedLoginTypeLevel === '5' && (
                      <div className='input-wrap'>
                        <p className='label'>통신사 선택</p>
                        <select value={selectedTelecom} onChange={handleTelecomChange}>
                          <option value="">통신사를 선택해주세요</option>
                          {telecomOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </>

                ) : (
                    <p>인증 데이터가 없습니다.</p>
                )}
                {isAuthRequested ? (
                  <>
                    <p className="guide-txt">카카오톡에서 인증 후 완료버튼을 눌러주세요.</p>
                    <button type="button" onClick={handleDismissalResult}>
                    인증완료
                    </button>
                  </>
                ) : (
                    <button type="button" onClick={handleDismissalRequest} >기존 대리인 해임신청</button>
                )}
            </div>
            {loading && (
                <div className='loading-spinner'>
                    <div className='spinner'></div>
                    <p>인증을 확인중입니다.<br/>잠시만 기다려주세요</p>
                </div>
            )}
          </div>
        </div>
        </div>
        <Modal
        isOpen={modalIsOpen}
        message={modalMessage}
        onClose={closeModal}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default Dismissal;