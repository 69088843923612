import React, { useState, useEffect } from 'react';
import { fetchBoards, fetchPlayers, fetchWebDetail } from '../../api';
import { useParams, Link, useNavigate } from 'react-router-dom';
import LayoutClient from '../../components/layout/LayoutClient';

import AOS from "aos";
import "../../aos.css";

const ApplyMain = () => {
  const navigate = useNavigate();
  const { shopname, webname } = useParams();
  const [players, setPlayers] = useState([]);
  const [boards, setBoards] = useState([]);
  const [details, setDetails] = useState([]);

  const [currentPlayer, setCurrentPlayer] = useState(null);
  const [currentBoard, setCurrentBoard] = useState(null);

  useEffect(() => {
    AOS.init();
  },[])

  useEffect(() => {
    loadPlayers();
    loadBoards();
    loadDetails();
  }, []);

  useEffect(() => {
    if (players.length > 0 && boards.length > 0) {
      filterData();
    }
  }, [players, boards, shopname, webname]);

  const loadPlayers = () => {
    fetchPlayers().then((response) => {
      setPlayers(response.data);
    }).catch((error) => {
      console.error("Error loading players:", error);
    });
  };
  
  const loadBoards = () => {
    fetchBoards().then(response => {
      setBoards(response.data);
    }).catch(error => {
      console.error('Error loading teams:', error);
    });
  };

  const loadDetails = () => {
    fetchWebDetail().then(response => {
      setDetails(response.data[0]);
    }).catch(error => {
      console.error('Error loading teams:', error);
    });
  };

  const filterData = () => {
    const board = boards.find(b => b.name_en === shopname);
    const player = players.find(p => p.name_en === webname);

    setCurrentBoard(board || null);
    setCurrentPlayer(player || null);
  };

  return (
    <>
      <LayoutClient />
      <div className='client-contents'>
        <div className='inner'>
          <div className='image-wrap'><img src={`${process.env.PUBLIC_URL}/img/img-main.svg`} /></div>
          <div className='contents-wrap'>
              {currentBoard && currentPlayer && (
              <>
                <div className='title-wrap'>
                  <p className='title'>{currentPlayer.name}<br/>세금신고 및 기장신청서</p>
                </div>
                <div className='image-wrap mo'><img src={`${process.env.PUBLIC_URL}/img/img-main.svg`} /></div>
                <div className='description-wrap'>
                  <p className='title'>{details.apply_title}</p>
                  <p className='description'>{details.apply_desc}</p>
                </div>
                <div className='link-wrap pc' data-aos="fade-in" data-aos-duration="600" data-aos-easing="ease-in-sine">
                    <Link to={`/apply/${shopname}/${webname}/check`}>세금신고 및 기장 신청하기</Link>
                </div>
                <div className='link-wrap mo'>
                    <Link to={`/apply/${shopname}/${webname}/check`}>세금신고 및 기장 신청하기</Link>
                </div>
              </>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplyMain;