import React, { useState, useEffect } from 'react';
import { sendTaxListAuth, sendTaxAuthResult, fetchWebDetail } from '../../api';
import { useLocation, useParams, Link, useNavigate } from 'react-router-dom';
import LayoutClient from '../../components/layout/LayoutClient';
import Modal from '../cms/ConsentModal.js';
const TaxList = () => {
  const [formData, setFormData] = useState({
    organization: "0004",
    loginType: "5",
    loginTypeLevel: "1",
    userName: '',
    identity: '',
    phoneNo: '',
    telecom: ''
  });
  const [privacyData, setPrivacy ] = useState({
    dismissal_privacy:''
  });
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [resultData, setResultData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [authResponse, setAuthResponse] = useState(null);  // 간편인증 요청 응답 상태
  const [signedData, setSignedData] = useState(''); // 간편인증 후 사용자가 입력한 서명 데이터
  const [isAuthRequested, setIsAuthRequested] = useState(false); // 인증 요청 상태
  const navigate = useNavigate();

  useEffect(() => {
    fetchWebDetail()
      .then((response) => {
        const data = response.data[0]; // 첫 번째 객체를 가져옴
        const dismissalPrivacy = data.dismissal_privacy; // main_title만 가져옴
        setPrivacy({ dismissal_privacy: dismissalPrivacy || '' }); // 필요한 상태에만 저장
      })
      .catch((error) => {
        console.error('Error fetching:', error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // 간편인증 요청 핸들러
  const handleAuthRequest = async (e) => {
    e.preventDefault();

    // 생년월일 8자리 체크
    if (formData.identity.length !== 8) {
      alert('생년월일을 8자리로 입력해주세요');
      return;  // 함수 종료
    }
    
    setIsAuthRequested(true);
    setErrorMessage('');

    alert('간편인증 요청 중입니다.');
    console.log(formData);

    try {
      const response = await sendTaxListAuth(formData);
      setAuthResponse(response);

      if (response.result.code === 'CF-03002') {
        alert('간편인증이 진행 중입니다. 인증을 완료해주세요.');
      }
    } catch (error) {
      console.error('Error sending auth request:', error);
      alert('간편인증 요청 중 오류가 발생했습니다. \n 정보를 다시 확인해주세요.');
      setIsAuthRequested(false);
    }
  };

  // 간편인증 완료 후 재요청 핸들러
  const handleAuthResult = async () => {
    setErrorMessage('');
    if (!authResponse) {
      alert('먼저 간편인증 요청을 진행해주세요.');
      return;
    }

    if (!privacyAccepted) {
      alert('개인정보 처리방침에 동의해주세요.');
      return;
    }

    setLoading(true);

    try {
      const authData = {
        organization: "0004",
        loginType: "5",
        loginTypeLevel: formData.loginTypeLevel,  // 사용자가 선택한 인증 방식
        userName: formData.userName,
        identity: formData.identity,
        phoneNo: formData.phoneNo,
        telecom: formData.telecom, // 통신사 정보 추가
        simpleAuth: '1', // 간편인증 방식
        is2Way: true,
        twoWayInfo: {
          jobIndex: authResponse.data.jobIndex,
          threadIndex: authResponse.data.threadIndex,
          jti: authResponse.data.jti,
          twoWayTimestamp: authResponse.data.twoWayTimestamp,
        }
      };

      console.log(authData);

      // 간편인증 완료 후 재요청
      const resultResponse = await sendTaxAuthResult(authData);
      const { result, data } = resultResponse;
      if (result.code === 'CF-00000') {
        console.log(result, data);

        const dataArray = Array.isArray(data) ? data : [data];
        if (dataArray.length > 0) {
          for (var i = 0; i < dataArray.length; i++) {
            const firm = dataArray[i];
            if (firm.resInfoProvideAgreeYN === '동의') {
              navigate('/dismissal/list', {
                state: {
                  resultData: firm,
                  userName: formData.userName,
                  identity: formData.identity,
                  phoneNo: formData.phoneNo
                }
              });
              return;  // 처리 후 함수 종료
            } else {
              alert('기존 수임동의되어있던 세무대리인이 없습니다. \n카카오 채널톡을 통해 신규 신청 문의 부탁드립니다.');
            }
          }
        } else {
          alert('기존 수임동의되어있던 세무대리인이 없습니다. \n카카오 채널톡을 통해 신규 신청 문의 부탁드립니다.');
        }
      } else {
        alert('기존 수임동의되어있던 세무대리인이 없습니다. \n카카오 채널톡을 통해 신규 신청 문의 부탁드립니다.');
      }

    } catch (error) {
      console.error('Error sending auth result:', error);
      alert('인증 중 오류가 발생했습니다. \n정보를 다시 확인해주세요.');
      setIsAuthRequested(false);
    } finally {
      setLoading(false); // 로딩 종료
    }
  };


  return (
    <>
      <LayoutClient />
      <div className='client-contents'>
        <div className='inner'>
          <div className='image-wrap'><img src={`${process.env.PUBLIC_URL}/img/img-write.svg`} /></div>
          <div className='contents-wrap cms'>
            <form onSubmit={handleAuthRequest} className='login-form'>
              <p className='sub-title'>기존 세무대리인 리스트 확인을 위해<br/> 간편인증을 진행해주세요.</p>
                <div className='consent-section cms'>
                  <div className='desc-wrap'>
                    <p>
                      • 필수정보 입력 전, 아래 개인정보 처리방침을 확인해주세요.
                    </p>
                  </div>
                  <div className='agree'>
                    <label>
                      <input
                        type="checkbox"
                        checked={privacyAccepted}
                        onChange={() => setPrivacyAccepted(!privacyAccepted)}
                      />
                      개인정보 처리방침에 동의합니다.
                    </label>
                    <button type="button" className="viewMore" onClick={() => setShowPrivacyModal(true)}>전문보기</button>
                  </div>
                </div>
                <div className='input-wrap'>
                  <p className='label'>인증방식 선택</p>
                  <div className='radio-wrap'>
                  <label>
                    <input
                      type="radio"
                      name="loginTypeLevel"
                      value="5"
                      onChange={handleChange}
                    />
                    PASS(통신사)
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="loginTypeLevel"
                      value="3"
                      onChange={handleChange}
                    />
                    삼성패스
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="loginTypeLevel"
                      value="6"
                      onChange={handleChange}
                    />
                    네이버
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="loginTypeLevel"
                      value="8"
                      onChange={handleChange}
                    />
                    Toss
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="loginTypeLevel"
                      value="1"
                      onChange={handleChange}
                    />
                    카카오톡
                  </label>
                  </div>
                </div>
                {formData.loginTypeLevel === "5" && (
                  <div className='input-wrap'>
                    <p className='label'>통신사 선택</p>
                    <select name="telecom" value={formData.telecom} onChange={handleChange} required>
                      <option value="">통신사 선택</option>
                      <option value="0">SKT</option>
                      <option value="1">KT</option>
                      <option value="2">LG U+</option>
                    </select>
                  </div>
                )}

                <div className='input-wrap'>
                    <p className='label'>이름</p>
                    <input type="text" name="userName" placeholder="대표자명 혹은 개인성명" value={formData.userName} onChange={handleChange} required />
                </div>
                <div className='input-wrap'>
                    <p className='label'>생년월일</p>
                    <input type="number" name="identity" inputmode="numeric" placeholder="생년월일 8자 ex)19900618" value={formData.identity} onChange={handleChange} required />
                </div>
                <div className='input-wrap'>
                    <p className='label'>휴대전화번호</p>
                    <input type="number" name="phoneNo" inputmode="numeric" placeholder="휴대전화번호('-'없이 숫자로 입력)" value={formData.phoneNo} onChange={handleChange} required />
                </div>

                {isAuthRequested ? (
                  <>
                    <p className="guide-txt">인증앱에서 인증 후 완료버튼을 눌러주세요.</p>
                    <button type="button" onClick={handleAuthResult}>
                    인증완료
                    </button>
                  </>
                ) : (
                    <button type="submit" >간편인증신청</button>
                )}
            </form>
            {loading && (
                <div className='loading-spinner'>
                    <div className='spinner'></div>
                    <p>인증을 확인중입니다.<br/>잠시만 기다려주세요</p>
                </div>
            )}
          </div>
        </div>
      </div>
      <Modal isOpen={showPrivacyModal} onClose={() => setShowPrivacyModal(false)} title="개인정보 처리방침">
        {privacyData.dismissal_privacy}
      </Modal>
    </>
  );
};

export default TaxList;