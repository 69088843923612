import React, { useState, useEffect } from 'react';
import { fetchWebDetail, updateWebDetail } from '../../api';
import { useNavigate } from 'react-router-dom';

const WebForm = ({ toolSettingId, onSave, onDelete }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('agree');
  const [formData, setFormData] = useState({
    main_title: '',
    main_desc: '',
    apply_title: '',
    apply_desc: '',
    agree_title: '',
    agree_desc: '',
    confirm_title: '',
    confirm_desc: '',
    cms_title:'',
    cms_desc:'',
    cms_c_title:'',
    cms_c_desc:'',
    info_provide_desc:'',
    dismissal_title:'',
    dismissal_desc:'',
    overlap_title:'',
    overlap_desc:'',
    dismissal_privacy:'',
    cms_privacy:''
  });

  useEffect(() => {
    fetchWebDetail().then((response) => {
        const data = response.data[0];
        console.log(data);
        setFormData({
            main_title: data.main_title || '',
            main_desc: data.main_desc || '',
            apply_title: data.apply_title || '',
            apply_desc: data.apply_desc || '',
            agree_title: data.agree_title || '',
            agree_desc: data.agree_desc || '',
            confirm_title: data.confirm_title || '',
            confirm_desc: data.confirm_desc || '',
            cms_title: data.cms_title || '',
            cms_desc: data.cms_desc || '',
            cms_c_title: data.cms_c_title || '',
            cms_c_desc: data.cms_c_desc || '',
            info_provide_desc: data.info_provide_desc || '',
            dismissal_title:data.dismissal_title || '',
            dismissal_desc:data.dismissal_desc || '',
            overlap_title:data.overlap_title || '',
            overlap_desc:data.overlap_desc || '',
            dismissal_privacy:data.dismissal_privacy || '',
            cms_privacy:data.cms_privacy || ''
        });
    }).catch(error => {
    console.error('Error fetching:', error);
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateWebDetail(formData).then((onSave) => {
        alert('정보가 저장되었습니다.');
    });
  };

  // 탭 전환 함수
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // 이전 페이지로 돌아가는 함수
  const handleGoBack = () => {
    navigate(-1); // 이전 페이지로 이동
  };

    // 탭에 따라 제목을 설정하는 함수
    const getTitleByTab = () => {
      switch (activeTab) {
        case 'agree':
          return '수임동의 프로세스';
        case 'cms':
          return 'CMS 프로세스';
        case 'dismiss':
          return '기존 대리인 조회/해임';
        case 'privacy':
          return '개인정보처리방침 관리';
        default:
          return '페이지 설명 수정';
      }
    };


  return (
    <div>
      <div className="tab-menu">
        <button className={activeTab === 'agree' ? 'active' : ''} onClick={() => handleTabClick('agree')}>수임동의 프로세스</button>
        <button className={activeTab === 'cms' ? 'active' : ''} onClick={() => handleTabClick('cms')}>CMS 프로세스</button>
        <button className={activeTab === 'dismiss' ? 'active' : ''} onClick={() => handleTabClick('dismiss')}>기존 대리인 조회/해임</button>
        <button className={activeTab === 'privacy' ? 'active' : ''} onClick={() => handleTabClick('privacy')}>개인정보처리방침 관리</button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className='title-wrap'>
          <p className="title">{getTitleByTab()}</p>
          <div className='button-wrap'>
            <button type="submit">저장</button>
          </div>
        </div>
        {activeTab === 'agree' && (
          <div>
            <div className="input-wrap align-top">
              <p className="label">메인 페이지</p>
              <div className="desc-wrapper">
                <input type="text" name="main_title" value={formData.main_title} onChange={handleChange} placeholder="메인페이지 타이틀" />
                <textarea name="main_desc" value={formData.main_desc} onChange={handleChange} placeholder="메인페이지 설명"></textarea>
              </div>
            </div>
            <div className="input-wrap align-top">
              <p className="label">수임동의 초기 페이지</p>
              <div className="desc-wrapper">
                <input type="text" name="apply_title" value={formData.apply_title} onChange={handleChange} placeholder="인트로 페이지 타이틀" />
                <textarea name="apply_desc" value={formData.apply_desc} onChange={handleChange} placeholder="인트로 페이지 설명"></textarea>
              </div>
            </div>
            <div className="input-wrap align-top">
              <p className="label">수임동의 안내 페이지</p>
              <div className="desc-wrapper">
                <input type="text" name="agree_title" value={formData.agree_title} onChange={handleChange} placeholder="수임동의 안내 페이지 타이틀" />
                <textarea name="agree_desc" value={formData.agree_desc} onChange={handleChange} placeholder="수임동의 안내 페이지 설명"></textarea>
              </div>
            </div>
            <div className="input-wrap align-top">
              <p className="label">수임동의 완료 페이지</p>
              <div className="desc-wrapper">
              <input type="text" name="confirm_title" value={formData.confirm_title} onChange={handleChange} placeholder="신청완료 페이지 타이틀" />
              <textarea name="confirm_desc" value={formData.confirm_desc} onChange={handleChange} placeholder="신청완료 페이지 설명"></textarea>
              </div>
            </div>
            <div className="input-wrap align-top">
              <p className="label">수임동의 중복 페이지</p>
              <div className="desc-wrapper">
              <input type="text" name="overlap_title" value={formData.overlap_title} onChange={handleChange} placeholder="중복 페이지 타이틀" />
              <textarea name="overlap_desc" value={formData.overlap_desc} onChange={handleChange} placeholder="중복 페이지 설명"></textarea>
              </div>
            </div>
            
            <div className="input-wrap align-top">
              <p className="label">{'('}수임납세자 등록{')'}<br/> 타소득포함/해당사업장 설명</p>
              <div className="desc-wrapper">
              <textarea name="info_provide_desc" value={formData.info_provide_desc} onChange={handleChange} placeholder="최초 수임납세자 등록 시, 타소득포함/해당사업장의 고객 이해를 돕기 위한 설명입니다."></textarea>
              </div>
            </div>
          </div>
        )}

        {activeTab === 'cms' && (
          <div>
            <div className="input-wrap align-top">
              <p className="label">CMS 초기 페이지</p>
              <div className="desc-wrapper">
              <input type="text" name="cms_title" value={formData.cms_title} onChange={handleChange} placeholder="CMS 신청 페이지 타이틀" />
              <textarea name="cms_desc" value={formData.cms_desc} onChange={handleChange} placeholder="CMS 신청 페이지 설명"></textarea>
              </div>
            </div>
            <div className="input-wrap align-top">
              <p className="label">CMS 신청완료 페이지</p>
              <div className="desc-wrapper">
              <input type="text" name="cms_c_title" value={formData.cms_c_title} onChange={handleChange} placeholder="CMS 신청 완료 페이지 타이틀" />
              <textarea name="cms_c_desc" value={formData.cms_c_desc} onChange={handleChange} placeholder="CMS 신청 완료 페이지 설명"></textarea>
              </div>
            </div>
          </div>
        )}

        {activeTab === 'dismiss' && (
          <div>
            <div className="input-wrap align-top">
              <p className="label">{'('}기존 대리인 조회/해임 페이지{')'} 인트로 페이지 제목</p>
              <div className="desc-wrapper">
              <input type="text" name="cms_c_title" value={formData.dismissal_title} onChange={handleChange} placeholder="인트로페이지 제목영역" />
              <textarea name="dismissal_desc" value={formData.dismissal_desc} onChange={handleChange} placeholder="인트로페이지 설명 영역"></textarea>
              </div>
            </div>
          </div>
        )}

        {activeTab === 'privacy' && (
          <div>
            <div className="input-wrap align-top">
              <p className="label">{'('}CMS 신청페이지{')'}<br/> 개인정보처리방침 설명</p>
              <div className="desc-wrapper">
              <textarea name="cms_privacy" value={formData.cms_privacy} onChange={handleChange} placeholder="CMS 신청 정보 입력 시, 개인정보처리방침"></textarea>
              </div>
            </div>
            <div className="input-wrap align-top">
              <p className="label">{'('}기존 대리인 조회/해임 페이지{')'}<br/> 개인정보처리방침 설명</p>
              <div className="desc-wrapper">
              <textarea name="dismissal_privacy" value={formData.dismissal_privacy} onChange={handleChange} placeholder="기존 대리임 조회 정보 입력 시, 개인정보처리방침"></textarea>
              </div>
            </div>
          </div>
        )}
        
        
        <button type="submit">저장</button>
      </form>
    </div>
  );
};

export default WebForm;