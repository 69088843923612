import React from 'react';
import { useLocation } from 'react-router-dom';
import LayoutClient from '../../components/layout/LayoutClient';

const Confirmed = () => {
  const location = useLocation();
  const { resultData } = location.state || {};

  console.log(resultData);

  return (
    <>
      <LayoutClient />
      <div className='client-contents'>
        <div className='inner'>
          <div className='image-wrap'><img src={`${process.env.PUBLIC_URL}/img/img-write.svg`} /></div>
          <div className='contents-wrap'>
            <div className='login-form'>
              <p className='sub-title'>이미 아이우리세무법인에 등록되어있습니다.</p>
                {resultData ? (
                    <div className='result-details'>
                        <div className='input-wrap single'>
                            <div className='inputs'>
                            <p className='label'>상호명</p> {resultData[0].resCompanyNm}
                            </div>
                            <div className='inputs'>
                            <p className='label'>세무대리인 사업자번호</p> {resultData[0].resCompanyIdentityNo}
                            </div>
                            <div className='inputs'>
                            <p className='label'>수임일자</p> {resultData[0].resCommissionDate}
                            </div>
                            <div className='inputs'>
                            <p className='label'>수임동의 여부</p> {resultData[0].resInfoProvideAgreeYN}
                            </div>
                        </div>
                    </div>
                ) : (
                    <p>인증 데이터가 없습니다.</p>
                )}
            </div>
          </div>
        </div>
        </div>
    </>
  );
};

export default Confirmed;