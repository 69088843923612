import React, { useState, useEffect } from 'react';

import Layout from '../../components/layout/Layout';
import WebForm from '../../components/web/WebForm';

const App = () => {
  return (
    <>
      <Layout />
      <div className='contents'>
        <div className='titleArea'>웹페이지 설명 관리</div>
        <div className='single-form-wrap'>
          <WebForm />
        </div>
      </div>
    </>
  );
};

export default App;