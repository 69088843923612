import React, { useState, useEffect } from 'react';

import Layout from '../../components/layout/Layout';
import MemoForm from '../../components/memo/MemoForm';

const App = () => {
  return (
    <>
      <Layout />
      <div className='contents'>
        <div className='titleArea'>메모</div>
        <div className='single-form-wrap'>
          <MemoForm />
        </div>
      </div>
    </>
  );
};

export default App;