// App.js

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './style/common.css';
import { AuthContextProvider } from './context/AuthContext';
import { MediaQueryProvider } from './context/MediaQueryContext';
import PrivateRoute from './components/PrivateRoute';

import AdminRoute from './components/AdminRoute';

// DashBoard

// Shop
import ShopList from './dashboard/shop/list';
import ShopWrite from './dashboard/shop/write';
import ShopModify from './dashboard/shop/modify';

// Page
import PageList from './dashboard/page/list';
import PageWrite from './dashboard/page/write';
import PageModify from './dashboard/page/modify';

// Member
import MemberList from './dashboard/member/list';

// Member
import CmsComList from './dashboard/cms/list';

// Service
import ServiceList from './dashboard/service/list';
import ServiceWrite from './dashboard/service/write';
import ServiceModify from './dashboard/service/modify';

// Web
import WebModify from './dashboard/web/write';
 
// memo
import MemoModify from './dashboard/memo/write';

// Client Web
import Main from './clients/Main';

import ApplyMain from './clients/apply/index';
import Check from './clients/apply/check';
import Verify from './clients/apply/verify';
import VerifyTax from './clients/apply/verifyTax';
import ApplyCms from './clients/cms/apply';
import ApplyCmsForm from './clients/cms/applyform';
import CmsConfirm from './clients/cms/confirm';
import ApplyConfirm from './clients/apply/confirm';
import TaxList from './clients/apply/taxList';
import Overlap from './clients/apply/overlap';


import Dismissal from './clients/apply/dismissal';
import BeforeAgree from './clients/apply/beforeAgree';
import Confirmed from './clients/apply/confirmed';

import DismissalMain from './clients/dismissal/intro';
import DismissalForm from './clients/dismissal/form';
import DismissalList from './clients/dismissal/list';
import DismissalResult from './clients/dismissal/result';


import Login from './clients/auth/Login';
import DiscordCallback from './clients/DiscordCallback';
import GoogleCallback from './clients/GoggleCallback';
import NaverCallback from './clients/NaverCallback';
import Signup from './clients/auth/Signup';

import ScrollToTop from "./components/ScrollTop";
import AdminLogin from "./components/AdminLogin";
import DashboardMain from "./dashboard/index";

function App() {
  return (
      <Router>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
          <meta property="og:url" content="https://taxson.co.kr" />
          <meta property="og:image" content="https://taxson.co.kr/img/og-image.png" /> 
          <meta property="og:title" content="아이우리 세무법인" /> 
          <meta property="og:description" content="아이우리 세무법인 세무기장 신청 서비스" />
          <title>아이우리 세무법인 세무기장 신청서</title>
          <link rel="shortcut icon" href={`${process.env.PUBLIC_URL}/img/iwoori.ico`} />
        </Helmet>
        <MediaQueryProvider>
        <ScrollToTop />
        <Routes>
          {/* DashBoard */}
          <Route path="/dashboard/login" element={<AdminLogin />} />
          <Route path='/dashboard' element={<AdminRoute element={<DashboardMain />} />}></Route>
          
          {/* Dashboard > Shop */}
          <Route path='/dashboard/shop/list' element={<AdminRoute element={<ShopList />} />}></Route>
          <Route path='/dashboard/shop/write' element={<AdminRoute element={<ShopWrite />} />}></Route>
          <Route path="/dashboard/shop/modify/:id" element={<AdminRoute element={<ShopModify />} />}></Route>

          {/* Dashboard > Page */}
          <Route path='/dashboard/page/list' element={<AdminRoute element={<PageList />} />}></Route>
          <Route path='/dashboard/page/write' element={<AdminRoute element={<PageWrite />} />}></Route>
          <Route path="/dashboard/page/modify/:id" element={<AdminRoute element={<PageModify />} />}></Route>

          {/* Dashboard > Member */}
          <Route path='/dashboard/member/list' element={<AdminRoute element={<MemberList />} />}></Route>

          {/* Dashboard > Cms */}
          <Route path='/dashboard/cms/list' element={<AdminRoute element={<CmsComList />} />}></Route>

          {/* Dashboard > Service */}
          <Route path='/dashboard/service/list' element={<AdminRoute element={<ServiceList />} />}></Route>
          <Route path='/dashboard/service/write' element={<AdminRoute element={<ServiceWrite />} />}></Route>
          <Route path="/dashboard/service/modify/:id" element={<AdminRoute element={<ServiceModify />} />}></Route>

          {/* Dashboard > web */}
          <Route path='/dashboard/web/modify' element={<AdminRoute element={<WebModify />} />}></Route>

          {/* Dashboard > memo */}
          <Route path='/dashboard/memo' element={<AdminRoute element={<MemoModify />} />}></Route>

          {/* Client*/}
          <Route path="/" element={<Main />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />

          <Route path="/auth/discord/callback" element={<DiscordCallback />} />
          <Route path="/auth/google/callback" element={<GoogleCallback />} />
          <Route path="/auth/naver/callback" element={<NaverCallback />} />

          <Route path="/apply/:shopname/:webname" element={<ApplyMain />} />
          <Route path="/apply/:shopname/:webname/check" element={<Check />} />
          <Route path="/apply/:shopname/:webname/verify" element={<Verify />} />
          <Route path="/apply/:shopname/:webname/verifyTax" element={<VerifyTax />} />
          <Route path="/apply/:shopname/:webname/confirm" element={<ApplyConfirm />} />
          <Route path="/apply/:shopname/:webname/taxList" element={<TaxList />} />
          <Route path="/apply/overlap" element={<Overlap />} />
          
          <Route path="/apply/:shopname/:webname/dismissal" element={<Dismissal />} />
          <Route path="/apply/:shopname/:webname/beforeAgree" element={<BeforeAgree />} />
          <Route path="/apply/:shopname/:webname/confirmed" element={<Confirmed />} />

          <Route path="/dismissal/intro" element={<DismissalMain />} />
          <Route path="/dismissal/form" element={<DismissalForm />} />
          <Route path="/dismissal/list" element={<DismissalList />} />
          <Route path="/dismissal/result" element={<DismissalResult />} />

          <Route path="/cms" element={<ApplyCms />} />
          <Route path="/cms/apply" element={<ApplyCmsForm />} />
          <Route path="/cms/confirm" element={<CmsConfirm />} />

        </Routes>
        </MediaQueryProvider>
      </Router>
  )
}

export default App;