import React, { useState, useEffect } from 'react';
import { fetchBoards, fetchPlayers, applyTax, fetchWebDetail } from '../../api';
import { useParams, Link, useNavigate } from 'react-router-dom';
import LayoutClient from '../../components/layout/LayoutClient';
import Modal from '../../components/modal';

const VerifyTax = () => {
  const [details, setDetails] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalConfirmAction, setModalConfirmAction] = useState(() => () => {});

  const openModal = (message, onConfirm) => {
    setModalMessage(message);
    setModalConfirmAction(() => onConfirm);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleConfirm = () => {
    modalConfirmAction();
    closeModal();
  };

  const { shopname, webname } = useParams();
  const [formData, setFormData] = useState({
    type: '0',
    companyIdentityNo: '',
    identity: '',
    phoneNo: '',
    userName: '',
    cert_file: '',
    key_file: '',
    cert_password:'',
    manage_no:'',
    manage_password:'',
    info_provide_range: '0'
  });
  const [players, setPlayers] = useState([]);
  const [boards, setBoards] = useState([]);
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const [currentBoard, setCurrentBoard] = useState(null);
  const [resultData, setResultData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadPlayers();
    loadBoards();
    loadDetails();
  }, []);

  const loadDetails = () => {
    fetchWebDetail().then(response => {
      setDetails(response.data[0]);
    }).catch(error => {
      console.error('Error loading teams:', error);
    });
  };

  useEffect(() => {
    if (players.length > 0 && boards.length > 0) {
      filterData();
    }
  }, [players, boards, shopname, webname]);

  const loadPlayers = () => {
    fetchPlayers().then((response) => {
      setPlayers(response.data);
    }).catch((error) => {
      console.error("Error loading players:", error);
    });
  };
  
  const loadBoards = () => {
    fetchBoards().then(response => {
      setBoards(response.data);
    }).catch(error => {
      console.error('Error loading teams:', error);
    });
  };

  const filterData = () => {
    const board = boards.find(b => b.name_en === shopname);
    const player = players.find(p => p.name_en === webname);
  
    setCurrentBoard(board || null);
    setCurrentPlayer(player || null);
  
    if (board) {
      setFormData(prevFormData => ({
        ...prevFormData,
        cert_file: board.cert_file,
        key_file: board.key_file,
        cert_password: board.cert_password,
        manage_no: board.manage_no,
        manage_password: board.manage_password
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // 간편인증 요청 핸들러
  const handleAuthRequest = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setLoading(true);

    try {
      const response  = await applyTax(formData);
      const { result, data } = response;

      if (result.code === 'CF-00000') {
        if ( data.resSuccessYN === '1') {
          navigate(`/apply/${shopname}/${webname}/verify`, {
            state: {
              userName: formData.userName,
              identity: formData.identity,
              phoneNo: formData.phoneNo
            }
          });
        } else {
          if ( data.resResultDesc.indexOf('이전해임일') != -1 ) {
            openModal(
              '당일 아이우리세무법인 해임신청을 진행하셨습니다. \n 내일 자정 이후 국세청 DB 갱신 후 재신청 가능합니다.',
              () => {}
            );
          } else {
            navigate(`/apply/${shopname}/${webname}/verify`, {
              state: {
                userName: formData.userName,
                identity: formData.identity,
                phoneNo: formData.phoneNo
              }
            });
          }
        }
      } else {
        setErrorMessage(result.message);
        openModal(
          '요청에 오류가 있습니다.\n 입력하신 정보를 다시 확인해주세요.',
          () => {}
        );
      }
    } catch (error) {
      console.error('Error sending auth request:', error);
      openModal(
        '오류가 발생했습니다. \n 정보를 다시 확인해주세요.',
        () => {}
      );
    } finally {
      setLoading(false); // 로딩 종료
    }
  };


  return (
    <>
      <LayoutClient />
      <div className='client-contents cms'>
      <div className='inner cms'>
          <div className='image-wrap'><img src={`${process.env.PUBLIC_URL}/img/img-write.svg`} /></div>
          <div className='contents-wrap cms'>
            <form onSubmit={handleAuthRequest} className='login-form'>
              <p className='sub-title'>먼저 아이우리세무법인에 수임납세자 신청을 진행해야 합니다.<br/>아래 신청인 정보를 입력해주세요</p>
              <div className='input-wrap'>
                <p className='label'>사업자유형</p>
                <div className='radio-wrap'>
                  <label>
                    <input type="radio" name="type" value="0" checked={formData.type === '0'} onChange={handleChange} /> 개인사업자
                  </label>
                  <label>
                    <input type="radio" name="type" value="2" checked={formData.type === '2'} onChange={handleChange} /> 비사업자
                  </label>
                </div>
              </div>
              {formData.type === '0' && (
                <div className='input-wrap'>
                  <p className='label'>사업자등록번호</p>
                  <input type="number" name="companyIdentityNo" inputmode="numeric" 
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(0, e.target.maxLength);
                  }}
                  maxLength={10} placeholder="'-'없이 숫자로 입력" value={formData.companyIdentityNo} onChange={handleChange} required />
                </div>
              )}
              
              <div className='input-wrap'>
                <p className='label'>이름</p>
                <input type="text" name="userName" placeholder="대표자명 혹은 개인성명" value={formData.userName} onChange={handleChange} required />
              </div>
              <div className='input-wrap'>
                <p className='label'>주민등록번호{'('}13자리{')'}</p>
                <input type="number" name="identity" inputmode="numeric"
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(0, e.target.maxLength);
                }}
                maxLength={13} placeholder="주민등록번호 13자리 ex)900618123456" value={formData.identity} onChange={handleChange} required />
              </div>
              <div className='input-wrap'>
                <p className='label'>휴대폰번호</p>
                <input type="number" name="phoneNo" inputmode="numeric"
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(0, e.target.maxLength);
                }}
                maxLength={11} placeholder="'-'없이 숫자로 입력" value={formData.phoneNo} onChange={handleChange} required />
              </div>
              <div className='input-wrap'>
                <p className='label'>정보제공범위</p>
                <div className='radio-wrap'>
                {formData.type === '0' ? (  // 개인사업자 선택 시
                  <>
                    <label>
                      <input type="radio" name="info_provide_range" value="0" checked={formData.info_provide_range === '0'} onChange={handleChange} /> 타소득포함
                    </label>
                    <label>
                      <input type="radio" name="info_provide_range" value="1" checked={formData.info_provide_range === '1'} onChange={handleChange} /> 해당사업장
                    </label>
                  </>
                ) : (  // 비사업자 선택 시
                  <label>
                    <input type="radio" name="info_provide_range" value="0" checked={formData.info_provide_range === '0'} onChange={handleChange} /> 타소득포함
                  </label>
                )}
                </div>
              </div>
              {details.info_provide_desc && (
                <div className='sub-desc'>
                  {details.info_provide_desc}
                </div>
              )}
              {errorMessage && (
                <div className='error-message'>
                  {errorMessage}
                </div>
              )}
              {loading && (
                <div className='loading-spinner'>
                  <div className='spinner'></div>
                  <p>이미 등록된 고객인지 확인중입니다<br/>잠시만 기다려주세요</p>
                </div>
              )}
              <button type="submit">신청하기</button>
            </form>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        message={modalMessage}
        onClose={closeModal}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default VerifyTax;