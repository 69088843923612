import React, { useState, useEffect } from 'react';
import { fetchMemoDetail, updateMemoDetail } from '../../api';
import { useNavigate } from 'react-router-dom';

const MemoForm = ({ toolSettingId, onSave, onDelete }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    contents: ''
  });

  useEffect(() => {
    fetchMemoDetail().then((response) => {
        const data = response.data[0];
        setFormData({
            contents: data.contents || '',
        });
    }).catch(error => {
    console.error('Error fetching:', error);
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateMemoDetail(formData).then((onSave) => {
        alert('정보가 저장되었습니다.');
    });
  };

  // 이전 페이지로 돌아가는 함수
  const handleGoBack = () => {
    navigate(-1); // 이전 페이지로 이동
  };


  return (
    <form onSubmit={handleSubmit}>
        <div className='title-wrap'>
            <p className="title">메모</p><div className='button-wrap'><button type="submit">저장</button></div>
        </div>

        <div className="input-wrap align-top">
          <div className="desc-wrapper memo">
            <textarea name="contents" value={formData.contents} onChange={handleChange} placeholder="메모"></textarea>
          </div>
        </div>
    </form>
  );
};

export default MemoForm;